<script>
	import { BookMarked, LifeBuoy, MessageCircleQuestion, ShieldCheck } from 'lucide-svelte';
	import Marqueeck from '@arisbh/marqueeck';
	import MarqueeLink from './marquee-link.svelte';
</script>

<Marqueeck options={{direction: 'left', speed: 30, gap: 16, paddingX: 0}}
					 class="mt-12 marquee-gradient">
	<div class="flex gap-x-4">
		<MarqueeLink href="/blog/gmail-yahoo-new-requirements-2024">
			<ShieldCheck class="size-5" />
			Gmail and Yahoo now require DMARC
		</MarqueeLink>

		<MarqueeLink href="/blog/why-you-need-dmarc">
			<MessageCircleQuestion class="size-5" />
			Why you need DMARC
		</MarqueeLink>

		<MarqueeLink href="/learn/email/spf">
			<BookMarked class="size-5" />
			Learn SPF
		</MarqueeLink>

		<MarqueeLink href="/learn/email/dkim">
			<BookMarked class="size-5" />
			Learn DKIM
		</MarqueeLink>

		<MarqueeLink href="/docs/what-is-dmarc">
			<LifeBuoy class="size-5" />
			How DMARC and DMARCwise can help you
		</MarqueeLink>
	</div>
</Marqueeck>
